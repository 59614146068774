import React, { SyntheticEvent, ReactElement } from 'react';
import ForecastingGenericModal from '../../../components/ForecastingGenericModal';
import environment from '../../../../../environment';
import axios from 'axios';
import { toast } from 'react-toastify';
const StoreConfigSaveModal = ({
  disabled,
  tierChanges,
  setTierChanges,
  children
}: {
  disabled: boolean;
  tierChanges: any[];
  setTierChanges: CallableFunction;
  children?: React.ReactNode;
}): ReactElement => {
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const body = tierChanges
        .filter((obj) => obj.originalTier !== obj.newTier)
        .map((obj) => ({
          id: obj.id,
          tier: obj.newTier
        }));
      axios
        .post(`${environment.apiPathForecasting}updateStoreTier`, body)
        .then(() => {
          toast.success('Store Tiers successfully updated');
          setTierChanges([]);
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    };

  return (
    <ForecastingGenericModal
      btnTitle='Save'
      confirmationMessage='Save Tier Changes'
      formSubtitle='This action cannot be undone'
      submitMessage='Submit'
      handleSubmit={handleSubmit}
      classes='btn--w-100-px btn--blue'
      disabled={disabled}
    >
      {children}
    </ForecastingGenericModal>
  );
};

export default StoreConfigSaveModal;
