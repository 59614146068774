import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import fileDownload from 'js-file-download';

import environment from '../../../../environment';
import ArrowRight from '../../../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../../../assets/icons/arrow-left.svg';
import InnerAccordion from '../../../../components/InnerAccordion';
import DeliveryDetails from '../DeliveryDetails';
import OrderItemList from '../OrderItemList';
import { IDeliveryGroup } from '../../../../interfaces/IOrder';
import DeliveryPipeline from '../DeliveryPipeline';
import CustomButton from '../../../../components/CustomButton';
import DispatchModal from '../DispatchModal';
import TradeInGoodsInModal from '../TradeInGoodsInModal';
import { IUserPermissions } from '../../../../interfaces/IAuthState';

const DeliveryGroup: React.FC<{
  orderID: string;
  orderSubinventory: string;
  group: IDeliveryGroup;
  clearData: CallableFunction;
  returnReferenceCode?: string;
  asnFile?: string;
  permissions: IUserPermissions;
}> = ({
  orderID,
  orderSubinventory,
  group,
  clearData,
  returnReferenceCode,
  asnFile,
  permissions
}) => {
  const [displayItems, setDisplayItems] = useState(true);
  const [updateTradeInVisible, setUpdateTradeInVisible] = useState(true);
  const [deliveryGroup, setDeliveryGroup] = useState(group);
  const navigate = useNavigate();
  const fileInput = React.useRef<any>();

  const base64Decode = (data: string, binary: boolean) => {
    if (!binary) {
      return atob(data);
    }

    return new Blob([Uint8Array.from(atob(data), (m) => m.charCodeAt(0))]);
  };

  const downloadAsnFile = async () => {
    if (asnFile) {
      try {
        const res = await axios.get(
          `${environment.apiPath}downloadAsnFile?orderId=${orderID}`,
          {
            responseType: 'text',
            ...environment.params
          }
        );
        fileDownload(
          base64Decode(
            res.data,
            res.headers['content-type'] === 'application/octet-stream'
          ),
          asnFile
        );
      } catch (err: AxiosError | any) {
        toast.error(
          err.response?.data?.error ||
            'An error occurred while downloading the file'
        );
      }
    }
  };

  const uploadGoodsInFile = async () => {
    if (fileInput?.current?.files.length === 1) {
      const file = fileInput.current.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      try {
        await axios.post(
          `${environment.apiPath}bucketUpload/dummy/${file.name}`,
          file,
          {
            headers: {
              'content-type': 'application/octet-stream',
              ...environment.params.headers
            }
          }
        );
        toast.success('File successfully uploaded');
      } catch (err: AxiosError | any) {
        toast.error(
          err.response?.data?.error ||
            'An error occurred while uploading the file'
        );
      }
    }
  };

  const redirectPick = () => {
    clearData();
    navigate(`/order-management?pickNumber=${group.pickNumber}`);
  };

  return (
    <InnerAccordion
      key={group.number}
      title={`${group.number} - ${
        group.details.dispatchMethod
          ? group.details.dispatchMethodName
            ? `${group.details.dispatchMethodName}, ${group.details.serviceName}`
            : group.details.dispatchMethod
          : 'REVERSE'
      }`}
      description={{
        code: 'Status',
        value: deliveryGroup.statusDesc || deliveryGroup.status
      }}
    >
      {displayItems ? (
        <>
          <DeliveryDetails
            details={group.details}
            consignmentNumber={deliveryGroup.consignmentNumber}
            consignmentNumbers={deliveryGroup.consignmentNumbers}
            pickNumber={group.pickNumber}
            inboundSlid={group.inboundSlid}
            outboundSlid={group.outboundSlid}
            redirectPick={redirectPick}
            returnReferenceCode={returnReferenceCode}
            isInbound={group.isInbound}
            isTradeIn={group.isTradeIn}
          />
          <OrderItemList
            items={deliveryGroup.items}
            subinventory={deliveryGroup.subinventory || ''}
            permissions={permissions}
            virtualSubinventory={orderSubinventory}
          />
          <div className='row row--justify-space-between'>
            {permissions.viewDispatchButton &&
              deliveryGroup.status === 'DISPATCH_INITIATED' && (
                <DispatchModal
                  orderID={orderID}
                  deliveryGroup={deliveryGroup}
                  setDeliveryGroup={(newGroup: any) =>
                    setDeliveryGroup(newGroup)
                  }
                />
              )}
            <div>
              {permissions.viewTradeInGoodsInButton &&
                deliveryGroup.isTradeIn &&
                ['AWAIT_GOODS_IN', 'TIRECEIVED', 'TIREADY'].indexOf(
                  deliveryGroup.status
                ) !== -1 &&
                updateTradeInVisible && (
                  <TradeInGoodsInModal
                    orderID={orderID}
                    deliveryGroup={deliveryGroup}
                    setButtonVisible={setUpdateTradeInVisible}
                  />
                )}
              {permissions.viewDownloadPreAlertFile &&
                (deliveryGroup.isTradeIn || deliveryGroup.isInbound) &&
                asnFile && (
                  <CustomButton
                    title='Download Pre-alert File'
                    classes='btn--blue btn--mr-10'
                    handleClick={() => downloadAsnFile()}
                  />
                )}
              {permissions.viewUploadGoodsInFileButton &&
                (deliveryGroup.isTradeIn || deliveryGroup.isInbound) &&
                ['AWAIT_GOODS_IN', 'TIRECEIVED', 'TIREADY'].indexOf(
                  deliveryGroup.status
                ) !== -1 &&
                !deliveryGroup.items.every(
                  (item) => item.status === 'COMPLETED'
                ) && (
                  <>
                    <CustomButton
                      title='Upload Goods-in File'
                      classes='btn--blue'
                      handleClick={() => fileInput?.current?.click()}
                    />
                    <input
                      type='file'
                      ref={fileInput}
                      style={{ display: 'none' }}
                      onChange={uploadGoodsInFile}
                    />
                  </>
                )}
            </div>
            <CustomButton
              title='Delivery pipeline'
              endIcon={<img src={ArrowRight} alt='Arrow right' />}
              handleClick={() => setDisplayItems(false)}
            />
          </div>
        </>
      ) : (
        <>
          <DeliveryPipeline history={deliveryGroup.history || []} />
          <div className='row'>
            <CustomButton
              title='Delivery details'
              classes='btn--grey'
              startIcon={<img src={ArrowLeft} alt='Arrow left' />}
              handleClick={() => setDisplayItems(true)}
            />
          </div>
        </>
      )}
    </InnerAccordion>
  );
};

DeliveryGroup.defaultProps = {
  returnReferenceCode: '',
  asnFile: ''
};

export default DeliveryGroup;
