import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => props.theme.colors.black};
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 20px 0;

  .search-menu-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .row-header {
    margin-bottom: 20px;
  }

  h2 {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }

  hr {
    margin: 20px 0;
  }

  .autocomplete__searchterms-container {
    width: 100% !important;
  }

  .view-more-button {
    font-family: Vodafone Rg;
    padding: 10px 8px 6px;
    text-transform: none;
    font-family: Vodafone Rg;
    color: #333333;
    font-size: 22px;
    width: 100%;
  }

  .inline-search-items {
    display: flex;
    flex-direction: row;
    width: 700px;
    gap: 10px;
  }

  .sku-group-search .label--w-45 {
    width: 100% !important;
  }
`;
