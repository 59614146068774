import React, { SyntheticEvent, ReactElement } from 'react';
import ForecastingGenericModal from '../../../components/ForecastingGenericModal';
import { toast } from 'react-toastify';
const StoreConfigClearModal = ({
  disabled,
  tierChanges,
  setTierChanges,
  gridApi,
  children
}: {
  disabled: boolean;
  tierChanges: any[];
  setTierChanges: CallableFunction;
  gridApi: any;
  children?: React.ReactNode;
}): ReactElement => {
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      tierChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(obj.id);
        if (rowDataNode) {
          const transaction = {
            update: [{ ...rowDataNode.data, tier: obj.originalTier }]
          };
          gridApi.applyTransaction(transaction);
        }
      });
      setTierChanges([]);
      toast.warning('Changes discarded');
      setLoading(false);
      setOpen(false);
    };

  return (
    <ForecastingGenericModal
      btnTitle='Clear'
      confirmationMessage='Please confirm you want to discard ALL changes'
      formSubtitle='This action cannot be undone'
      handleSubmit={handleSubmit}
      classes='btn--w-100-px btn--red'
      disabled={disabled}
      submitMessage='Submit'
      style={{ width: '100%' }}
    >
      {children}
    </ForecastingGenericModal>
  );
};

export default StoreConfigClearModal;
