import styled from 'styled-components';

export default styled('div')`
  .product-code-search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-code-search__input {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .MuiAlert-root {
      border-radius: 0;
      margin-top: 10px;
    }
  }

  .product-codes-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: ${(props: { expanded: boolean }) =>
      props.expanded ? '100%' : '100px'};
    overflow: ${(props: { expanded: boolean }) =>
      props.expanded ? 'visible' : 'hidden'};

    .MuiChip-root {
      font-family: Vodafone Lt;
      font-size: 18px;
      padding: 20px 7px;
      border-radius: 20px;
      margin: 10px 10px 0 0;
      background-color: #e0e0e0;
    }
  }

  .close-link {
    font-weight: 700;
    cursor: pointer;
    align-self: right;
    font-style: italic;
    text-decoration: underline;
  }

  .product-codes--justify-center {
    justify-content: center;
  }

  .product-codes--ml-50 {
    margin-left: -50%;
  }

  .view-more-button {
    text-transform: none;
    font-family: Vodafone Rg;
    color: ${(props) => props.theme.colors.black};
    font-size: 22px;
    width: 100%;
  }

  .MuiButton-root {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    img {
      margin-left: 20px;
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      -ms-transition: -ms-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transform: rotate(
        ${(props: { expanded: boolean }) => (props.expanded ? 180 : 0)}deg
      );
    }
  }
`;
