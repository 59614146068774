import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  .report__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .report__grid {
    height: 600px;
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;

    .report-cell-red {
      background-color: ${(props) => props.theme.colors.lightRed};
      color: white;
    }

    .report-cell-amber {
      background-color: ${(props) => props.theme.colors.yellow};
      color: white;
    }

    .report-cell-green {
      background-color: ${(props) => props.theme.colors.lightGreen};
      color: white;
    }
  }

  .last-updated--wrapper {
    margin-top: 20px;
    align-self: flex-end;
    display: flex;
  }

  .last-updated {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
  }

  .last-updated.green {
    background-color: #adcc5680; /* cav lightGreen with 50% opacity */
  }

  .last-updated.amber {
    background-color: #f9ca0080; /* cav yellow with 50% opacity */
  }

  .last-updated.red {
    background-color: ${(props) => props.theme.colors.lightRed};
  }

  .report__actions--divider {
    display: flex;
    gap: 10px;
  }
  .row--highlight {
    background-color: #f9ca0080;
  }
`;
