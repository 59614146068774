import React, { SyntheticEvent, ReactElement } from 'react';
import ForecastingGenericModal from '../ForecastingGenericModal';
import { IReplenChanges } from '../ReplenChanges/ReplenChanges';
import { toast } from 'react-toastify';
import { GridApi } from 'ag-grid-community';
const ForecastingClearModal = ({
  disabled,
  replenChanges,
  setReplenChanges,
  gridApi,
  children
}: {
  disabled: boolean;
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
  gridApi: GridApi | undefined;
  children?: React.ReactNode;
}): ReactElement => {
  const clearReplenChanges = () => {
    if (gridApi) {
      replenChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(String(obj.id));
        if (rowDataNode) {
          const transaction = {
            update: [
              { ...rowDataNode.data, actualReplenishment: obj.oldActual }
            ]
          };
          gridApi.applyTransaction(transaction);
        }
      });
      setReplenChanges([]);
    }
  };
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      clearReplenChanges();
      toast.warning('Changes discarded');
      setLoading(false);
      setOpen(false);
    };

  return (
    <ForecastingGenericModal
      btnTitle='Clear'
      confirmationMessage='Are you sure you want to discard changes?'
      formSubtitle='This action cannot be undone'
      handleSubmit={handleSubmit}
      classes='btn--w-100-px btn--red'
      submitMessage='Submit'
      disabled={disabled}
    >
      {children}
    </ForecastingGenericModal>
  );
};

export default ForecastingClearModal;
