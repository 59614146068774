import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Chip, Button, MenuItem, ClickAwayListener } from '@mui/material';

import StyledAutoCompleteSearch from './styles';
import ViewMoreIcon from '../../assets/icons/view-more.svg';
import ExpandIcon from '../../assets/icons/expand.svg';
import ControlledInput from '../ControlledInput/ControlledInput';
import CustomButton from '../CustomButton/CustomButton';

interface IAutoCompleteSearchProps {
  id: string;
  label: string;
  options: string[];
  searchTerms: string[];
  setSearchTerms: CallableFunction;
  placeholder?: string;
  classes?: string;
  style?: any;
}

const AutoCompleteSearch: React.FC<IAutoCompleteSearchProps> = ({
  id,
  label,
  options,
  searchTerms,
  setSearchTerms,
  placeholder,
  classes,
  style
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [suggestedOptions, setSuggestedOptions] = useState<string[]>(['']); // Top 3 options
  const [filteredOptions, setFilteredOptions] = useState<string[]>(['']); // [options] - [searchterms]
  const [expandedChip, setExpandedChip] = useState(false);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkSearchTermsOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expandedChip) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  const positiveModulo = (v: number, m: number) => ((v % m) + m) % m;

  const handleRemoveSearch = (term: string) => {
    setSearchTerms(searchTerms.filter((item) => item !== term));
    setFilteredOptions([...filteredOptions, term]);
  };

  const handleAddSearch = (term: string) => {
    if (options.includes(term)) {
      setSearchTerm('');
      setOpenDropdown(false);
      setSearchTerms([...searchTerms, term]);
      setFilteredOptions(filteredOptions.filter((item) => item !== term));
    }
  };

  const sortOptions = (opts: string[]) =>
    opts.sort((a, b) => a.split(' - ')[1].localeCompare(b.split(' - ')[1]));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = sortOptions(
      filteredOptions
        .filter((option) => option.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 7)
    );

    if (filtered.length === 0) {
      filtered.push('No results found');
    }

    setSuggestedOptions(filtered);
    setSearchTerm(value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      const nextTab = event.currentTarget.nextSibling
        ?.firstChild as HTMLElement;

      nextTab?.focus();
      setSearchTerm(nextTab.textContent || '');
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      const nextTab = event.currentTarget.nextSibling?.lastChild as HTMLElement;

      nextTab?.focus();
      setSearchTerm(nextTab.textContent || '');
      event.preventDefault();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      handleAddSearch(searchTerm);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setOpenDropdown(false);
    }
  };

  const handleMenuKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'ArrowDown') {
      const siblings = event.currentTarget.parentElement?.children;

      if (siblings) {
        const nextTab = siblings[(index + 1) % siblings.length] as HTMLElement;

        nextTab?.focus();
        setSearchTerm(nextTab.textContent || '');
        event.preventDefault();
      }
    } else if (event.key === 'ArrowUp') {
      const siblings = event.currentTarget.parentElement?.children;

      if (siblings) {
        const nextTab = siblings[
          positiveModulo(index - 1, siblings.length)
        ] as HTMLElement;

        nextTab?.focus();
        setSearchTerm(nextTab.textContent || '');
        event.preventDefault();
      }
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      handleAddSearch(searchTerm);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    setFilteredOptions(options.filter((item) => !searchTerms.includes(item)));
  }, [searchTerms, options]);

  useEffect(() => {
    const filtered = sortOptions(filteredOptions);

    if (filtered.length === 0) {
      filtered.push('No results found');
    }

    setSuggestedOptions(filtered.slice(0, 7));
  }, [filteredOptions]);

  useEffect(() => {
    checkSearchTermsOverflow(overflowingText.current);
  }, [searchTerms]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenDropdown(false);
      }}
    >
      <StyledAutoCompleteSearch style={style} expandedChip={expandedChip}>
        <div
          role='none'
          onKeyDown={(e) => {
            setOpenDropdown(true);
            handleInputKeyDown(e);
          }}
          className='autocomplete__input'
        >
          <ControlledInput
            id={`${id}-textbox`}
            type='text'
            label={label}
            value={searchTerm}
            handleChange={handleChange}
            placeholder={placeholder}
            classes='label--w-100 label--overflow-inherit'
            disabled={options.length === 0}
          />
          <CustomButton
            type='button'
            title=''
            classes='btn--w-10 btn--blue'
            handleClick={() => {
              setOpenDropdown(!openDropdown);
            }}
            disabled={options.length === 0}
            endIcon={
              <img
                src={ExpandIcon}
                alt='Expand search options'
                className={`expand-icon ${openDropdown && 'open'}`}
              />
            }
          />
        </div>
        {openDropdown && (
          <div className='autocomplete__dropdown'>
            {suggestedOptions.map((option, index) => (
              <MenuItem
                key={option}
                value={option}
                tabIndex={0}
                onKeyDown={(event: React.KeyboardEvent) => {
                  handleMenuKeyDown(event, index);
                }}
                onClick={() => {
                  handleAddSearch(option);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </div>
        )}
        <div
          ref={overflowingText}
          className={`autocomplete__searchterms-container ${classes}`}
        >
          {searchTerms.map((term: string) => (
            <Chip
              key={term}
              label={term}
              onDelete={() => handleRemoveSearch(term)}
            />
          ))}
        </div>
        {viewMoreBtnVisible && (
          <Button
            onClick={() => {
              setExpandedChip(!expandedChip);
            }}
            className={`view-more-button ${classes}`}
          >
            <span style={{ color: 'white' }}>
              {expandedChip ? 'View less' : 'View more'}
            </span>
            <img src={ViewMoreIcon} alt='ViewMore' />
          </Button>
        )}
      </StyledAutoCompleteSearch>
    </ClickAwayListener>
  );
};

AutoCompleteSearch.defaultProps = {
  placeholder: '',
  classes: '',
  style: null
};

export default AutoCompleteSearch;
