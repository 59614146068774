import React, { SyntheticEvent, ReactElement } from 'react';
import ForecastingGenericModal from '../ForecastingGenericModal';
import { IReplenChanges } from '../ReplenChanges/ReplenChanges';
import axios from 'axios';
import environment from '../../../../environment';
import { toast } from 'react-toastify';
const ForecastingSaveModal = ({
  disabled,
  replenChanges,
  setReplenChanges,
  getRowData,
  children
}: {
  disabled: boolean;
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
  getRowData: CallableFunction;
  children?: React.ReactNode;
}): ReactElement => {
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const body = replenChanges
        .filter((obj) => obj.actualReplenishment !== obj.oldActual)
        .map((obj) => ({
          id: obj.id,
          replenishment: obj.replenishment,
          actualReplenishment: obj.actualReplenishment
        }));
      axios
        .post(
          `${environment.apiPathForecasting}updateActualReplenishment`,
          body
        )
        .then(() => {
          setLoading(false);
          setOpen(false);
          toast.success('Actual Replenishment Values Updated');
          setReplenChanges([]);
          getRowData();
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          toast.error(err.message);
        });
    };

  return (
    <ForecastingGenericModal
      btnTitle='Save'
      confirmationMessage='Please review changes to be saved.'
      formSubtitle='This action cannot be undone'
      handleSubmit={handleSubmit}
      classes='btn--w-100-px btn--blue'
      submitMessage='Submit'
      disabled={disabled}
    >
      {children}
    </ForecastingGenericModal>
  );
};

export default ForecastingSaveModal;
