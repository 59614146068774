import React, { useState, useEffect, SyntheticEvent, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  GridApi,
  GridReadyEvent,
  NewValueParams,
  ValueSetterParams
} from 'ag-grid-community';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import StyledReport from './styles';
import environment from '../../environment';
import ForecastingSearchMenu from './components/ForecastingSearchMenu/ForecastingSearchMenu';
import ForecastingClearModal from './components/ForecastingClearModal';
import ForecastingSaveModal from './components/ForecastingSaveModal';
import CustomButton from '../../components/CustomButton';
import ControlledInput from '../../components/ControlledInput';
import DownloadIcon from '../../assets/icons/download.svg';
import StarRating from './components/StarRating';
import ReplenChanges from './components/ReplenChanges/ReplenChanges';
import EditTooltip from '../../components/EditTooltip/EditTooltip';
import InfoIcon from '../../assets/icons/info.svg';

interface IForecasting {
  id: number;
  productCode: string;
  storeCode: string;
  productName: string;
  storeName: string;
  soh: number;
  averageSales: number;
  lastDaysSales: number;
  availableToPromise: number;
  inTransit: number;
  daysCover: number;
  replenishment: number;
  actualReplenishment: number;
  dssc: number;
  multichannel: number;
  sgnPot: number;
  tier: number;
  totalReplenishment: number;
}

interface IReplenChanges {
  id: number;
  productCode: string;
  productName: string;
  storeCode: string;
  storeName: string;
  replenishment: number;
  actualReplenishment: number;
  oldActual: number;
}

interface IStoreObjects {
  id: string;
  name: string;
  compositeName: string;
}

const Forecasting: React.FC = () => {
  const [rowData, setRowData] = useState<IForecasting[]>([]);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [storeOptions, setStoreOptions] = useState<string[]>([]);
  const [daysCover, setDaysCover] = useState<string>('');
  const [repRequired, setRepRequired] = useState<boolean>(true);
  const [stores, setStores] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [storeTier, setStoreTier] = useState<string>('');
  const [replenChanges, setReplenChanges] = useState<IReplenChanges[]>([]);
  const [storeObjects, setStoreObjects] = useState<IStoreObjects[]>([]);
  const [forecastDates, setForecastDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [exportStatus, setExportStatus] = useState<string>('');
  const [exportDisable, setExportDisable] = useState<boolean>(false);
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [productDescription, setProductDescription] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const pageSize = 30000;

  const storeNames: string[] = stores.map((compositeName: string) => {
    const matchingObject: any | undefined = storeObjects.find(
      (obj: any) => obj.compositeName === compositeName
    );

    return matchingObject?.name || -1;
  });

  const getRowData = () => {
    gridApi?.showLoadingOverlay();
    let rows: any = [];
    let requestsWaiting = 1;
    const queryData = (nextOffset = '') => {
      setSearching(true);
      axios
        .get(
          `${
            environment.apiPathForecasting
          }getForecastingItems?pageSize=${pageSize}&nextOffset=${nextOffset}&productCodes=${productCodes.join(
            ','
          )}&stores=${storeNames.join(
            ','
          )}&daysCover=${daysCover}&storeTier=${storeTier}&selectedDate=${
            selectedDate
              ? `${selectedDate.getFullYear()}-${
                  selectedDate.getMonth() + 1
                }-${selectedDate.getDate()}`
              : ''
          }&repRequired=${repRequired}&productDescription=${productDescription}`
        )
        .then((res) => {
          rows = rows.concat(
            res.data.rows.map((row: any[]) =>
              _.zipObject(res.data.columns, row)
            )
          );

          if (res.data.nextOffsets.length > 0) {
            requestsWaiting = res.data.nextOffsets.length;
            res.data.nextOffsets.map((token: any) => queryData(token.offset));
          } else {
            requestsWaiting -= 1;
          }

          if (requestsWaiting === 0) {
            setSearching(false);
            setRowData(rows);
            gridApi?.hideOverlay();

            if (rows.length === 0) {
              gridApi?.showNoRowsOverlay();
            }
          }
        })
        .catch((err) => {
          setRowData([]);
          gridApi?.showNoRowsOverlay();
          if (
            (err.response &&
              (err.response.status === 413 || err.response.status === 504)) ||
            err.code === 'ERR_NETWORK'
          ) {
            toast.error('Too many results, please refine your search');
          } else {
            toast.error(err.message);
          }
          setSearching(false);
        });
    };
    queryData();
  };

  const getStores = () => {
    axios.get(`${environment.apiPathForecasting}getAllStores`).then(
      (res) => {
        const arrayOfNames: string[] = res.data.body.map(
          (obj: any) => obj.compositeName
        );
        setStoreOptions(arrayOfNames.filter((item) => item !== ' - '));
        setStoreObjects(res.data.body);
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  const getBulkOrderFile = () => {
    setExportStatus('Generating');
    setExportDisable(true);
    axios
      .get(`${environment.apiPathForecasting}getBulkOrderFile`, {
        responseType: 'json',
        ...environment.params
      })
      .then(
        (res: any) => {
          setExportStatus('Downloading');
          const downloadURL = async () => {
            toast.success('Downloading now...');
            const link = document.createElement('a');
            link.href = res.data.body;
            link.download = 'BulkOrderTemplate.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            await new Promise((r) => setTimeout(r, 2000));
            setExportStatus('Export');
            setExportDisable(false);
          };
          downloadURL();
        },
        (err) => {
          setExportDisable(false);
          setExportStatus('Export');
          toast.error(err.response);
        }
      );
  };

  const getForecastDates = () => {
    axios.get(`${environment.apiPathForecasting}getAllForecastDates`).then(
      (res: any) => {
        setForecastDates(res.data.body.map((date: string) => new Date(date)));
        setSelectedDate(new Date(res.data.body.slice(-1)));
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  useEffect(() => {
    getStores();
    getForecastDates();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setExportStatus('Export');
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setReplenChanges([]);
    getRowData();
  };

  const handleReplenChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(String(params.data.id));
      const prevReplenChanges = replenChanges.find(
        (obj) => obj.id === params.data.id
      );
      if (prevReplenChanges) {
        if (params.newValue === prevReplenChanges?.oldActual) {
          setReplenChanges(
            replenChanges.filter((obj) => obj.id !== params.data.id)
          );
        } else {
          setReplenChanges(
            replenChanges.map((obj) =>
              obj.id === params.data.id
                ? {
                    ...obj,
                    actualReplenishment: params.newValue
                  }
                : obj
            )
          );
        }
      } else {
        setReplenChanges([
          ...replenChanges,
          {
            id: params.data.id,
            productCode: params.data.productCode,
            productName: params.data.productName,
            storeCode: params.data.storeCode,
            storeName: params.data.storeName,
            replenishment: params.data.replenishment,
            actualReplenishment: params.newValue,
            oldActual: params.oldValue
          }
        ]);
      }
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              id: params.data.id,
              actualReplenishment: params.newValue
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const daysCoverCellClass = (params: any) => {
    if (params.value === undefined) {
      return '';
    }
    if (params.value < 2.0) {
      return 'report-cell-red';
    }
    if (params.value < 4.0) {
      return 'report-cell-amber';
    }

    return 'report-cell-green';
  };

  const lastUpdatedCellClass = (date: Date) => {
    const today = new Date();
    // Sets today as yesterday and resets the time to 00:00:00
    today.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);

    if (moment(date).isSame(today, 'day')) {
      return 'green';
    }
    if (date < today) {
      return 'amber';
    }

    return 'red';
  };

  const renderStars = (params: any) => {
    const cell = (
      <StarRating
        label='top-store-rating'
        params={params}
        readOnly={params.readOnly}
        classes={params.classes}
      />
    );

    return cell;
  };

  const renderEditTooltip = (params: any) => {
    const cell = (
      <div>
        <EditTooltip params={params} />
      </div>
    );

    return cell;
  };

  const replenishmentValidations = (params: ValueSetterParams) => {
    const parsedValue = Number(params.newValue);

    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue % 1 !== 0) {
      return false;
    }

    params.data.actualReplenishment = parsedValue;

    return true;
  };

  const columnDefs: any = [
    {
      headerName: 'DSSC',
      field: 'dssc',
      pinned: 'left',
      width: 70
    },
    {
      headerName: 'MULTICHANL',
      field: 'multichannel',
      pinned: 'left',
      width: 80
    },
    {
      headerName: 'SGN-POT',
      field: 'sgnPot',
      pinned: 'left',
      width: 80
    },
    {
      headerName: 'Total Replenishment',
      field: 'totalReplenishment',
      pinned: 'left',
      width: 110
    },
    {
      headerName: 'SKU',
      field: 'productCode',
      pinned: 'left',
      width: 68,
      enableRowGroup: true
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      minWidth: 100,
      enableRowGroup: true
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      minWidth: 150,
      enableRowGroup: true
    },
    {
      headerName: 'Store Tier',
      field: 'tier',
      minWidth: 95,
      cellRenderer: 'renderStars',
      enableRowGroup: true,
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'Description',
      field: 'productName',
      minWidth: 175,
      enableRowGroup: true
    },
    {
      headerName: 'Recommended Replenishment',
      field: 'replenishment',
      minWidth: 100
    },
    {
      headerName: 'Actual Replenishment',
      field: 'actualReplenishment',
      editable: true,
      onCellValueChanged: handleReplenChange,
      minWidth: 100,
      cellRenderer: renderEditTooltip,
      valueSetter: replenishmentValidations,
      cellEditor: 'agNumberCellEditor'
    },
    {
      headerName: 'Days Cover',
      field: 'daysCover',
      cellClass: daysCoverCellClass,
      minWidth: 100
    },
    {
      headerName: 'SOH',
      field: 'soh',
      minWidth: 65
    },
    {
      headerName: 'ATP',
      field: 'availableToPromise',
      minWidth: 60
    },
    {
      headerName: 'In Transit',
      field: 'inTransit',
      minWidth: 90
    },
    {
      headerName: 'Weekly Average Sales',
      field: 'averageSales',
      minWidth: 110
    },
    {
      headerName: '7 Days Sales',
      field: 'lastDaysSales',
      minWidth: 110
    }
  ];

  return (
    <>
      <HeroBanner title='Retail Replenishment' background={HeroProducts} />
      <ForecastingSearchMenu
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        storeOptions={storeOptions}
        stores={stores}
        repRequired={repRequired}
        setRepRequired={setRepRequired}
        setStores={setStores}
        storeTier={storeTier}
        setStoreTier={setStoreTier}
        daysCover={daysCover}
        setDaysCover={setDaysCover}
        handleSubmit={handleSubmit}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        searching={searching}
        setSearching={setSearching}
        forecastDates={forecastDates}
        productDescription={productDescription}
        setProductDescription={setProductDescription}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30 label--overflow-inherit'
          />
          <div className='report__actions--divider'>
            <ForecastingClearModal
              disabled={replenChanges.length === 0}
              replenChanges={replenChanges}
              setReplenChanges={setReplenChanges}
              gridApi={gridApi}
            >
              <ReplenChanges
                replenChanges={replenChanges}
                setReplenChanges={setReplenChanges}
                gridApi={gridApi}
                handleReplenChange={handleReplenChange}
                actions={false}
              />
            </ForecastingClearModal>
            <ForecastingSaveModal
              disabled={replenChanges.length === 0}
              replenChanges={replenChanges}
              setReplenChanges={setReplenChanges}
              getRowData={getRowData}
            >
              <ReplenChanges
                replenChanges={replenChanges}
                setReplenChanges={setReplenChanges}
                gridApi={gridApi}
                handleReplenChange={handleReplenChange}
                actions={true}
              />
            </ForecastingSaveModal>
            <CustomButton
              title={exportStatus}
              classes='btn--w-200-px btn--black'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={getBulkOrderFile}
              disabled={exportDisable}
            />
          </div>
        </div>
        <div className='last-updated--wrapper'>
          {selectedDate && (
            <div
              className={`last-updated ${lastUpdatedCellClass(selectedDate)}`}
            >
              <img src={InfoIcon} alt='Last updated' width='15px' />
              <span>
                <b>Last Updated:</b> {moment(selectedDate).format('YYYY-MM-DD')}
              </span>
            </div>
          )}
        </div>
        <div className='report__grid ag-theme-balham' id='replenishment-table'>
          <AgGridReact
            rowData={rowData}
            rowHeight={30}
            pagination
            defaultColDef={{
              resizable: true,
              flex: 1,
              sortable: true,
              filter: 'agTextColumnFilter'
            }}
            tooltipShowDelay={0}
            enableCellTextSelection
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns
            groupDisplayType='groupRows'
            rowGroupPanelShow='always'
            groupRowRendererParams={{
              innerRendererParams: {
                readOnly: true,
                classes: 'rating--shadow-grey'
              },
              innerRendererSelector: (params: any) => {
                if (params.node.field === 'tier') {
                  return {
                    component: 'renderStars'
                  };
                }

                return {
                  component: 'agGroupComponent'
                };
              }
            }}
            suppressRowGroupHidesColumns
            suppressRowClickSelection
            getRowId={(params) => params.data.id}
            getRowClass={(params) => {
              if (params.data) {
                if (
                  params.data.replenishment !== params.data.actualReplenishment
                ) {
                  return 'row--highlight';
                }
              }
            }}
            components={{ renderStars: renderStars }}
            columnDefs={columnDefs}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default Forecasting;
