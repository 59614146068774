import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GridApi,
  ICellRendererParams,
  NewValueParams
} from 'ag-grid-community';
import { Button } from '@mui/material';

import StyledStoreConfigHeader from './styles';
import Disable from '../../../../../assets/icons/disable.svg';
import { ITierChanges } from '../../StoreConfig';

interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
}

interface IStoreConfigHeaderProps {
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
  renderStars: (params: ICellRendererParams) => React.JSX.Element;
  gridApi: GridApi | undefined;
  actions: boolean;
}

const StoreConfigHeader: React.FC<IStoreConfigHeaderProps> = ({
  tierChanges,
  setTierChanges,
  renderStars,
  gridApi,
  actions
}) => {
  const removeTierChange = (params: ICustomRemoveCellRendererParams) => {
    const rowDataNode = params.gridApi.getRowNode(params.data.id);

    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            tier: params.data.originalTier
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);

      params.setTierChanges(
        params.tierChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const handleNewTierChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(params.data.id);
      if (params.data.originalTier === params.newValue) {
        const transaction = {
          remove: [params.data]
        };
        gridApi.applyTransaction(transaction);
        setTierChanges(tierChanges.filter((obj) => obj.id !== params.data.id));
      }
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              tier: params.data.newTier
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions remove--btn'>
        <Button
          type='button'
          onClick={() => {
            removeTierChange(params);
          }}
        >
          <img src={Disable} alt='Remove tier change' />
        </Button>
      </div>
    );

    return cell;
  };
  const columnDefs: any = [
    {
      headerName: 'Old Tier',
      field: 'originalTier',
      enableRowGroup: true,
      sortable: true,
      minWidth: 95,
      cellRenderer: 'renderStars',
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'New Tier',
      field: 'newTier',
      enableRowGroup: true,
      onCellValueChanged: handleNewTierChange,
      sortable: true,
      minWidth: 95,
      cellRenderer: 'renderStars',
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'Store Code',
      field: 'id',
      sortable: true,
      flex: 1,
      width: 100
    },
    {
      headerName: 'Store Name',
      field: 'name',
      sortable: true,
      flex: 2,
      width: 150
    }
  ];
  if (actions) {
    columnDefs.push({
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: 'RenderRemoveButton',
      cellRendererParams: { gridApi, tierChanges, setTierChanges },
      width: 100
    });
  }

  return (
    <StyledStoreConfigHeader fixed>
      <div className='change_report report__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          pagination
          defaultColDef={{
            resizable: true,
            sortable: true
          }}
          paginationPageSizeSelector={false}
          paginationPageSize={10}
          enableCellTextSelection
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
          rowData={tierChanges}
          components={{
            RenderRemoveButton: renderRemoveButton,
            renderStars: renderStars
          }}
          getRowId={(params) => params.data.id}
          rowGroupPanelShow='always'
          groupDisplayType='groupRows'
          groupRowRendererParams={{
            innerRendererParams: {
              readOnly: true,
              classes: 'rating--shadow-grey'
            },
            innerRendererSelector: (params: any) => {
              if (
                params.node.field === 'newTier' ||
                params.node.field === 'originalTier'
              ) {
                return {
                  component: 'renderStars'
                };
              }

              return {
                component: 'agGroupComponent'
              };
            }
          }}
          suppressRowGroupHidesColumns
          excelStyles={[
            {
              id: 'stringType',
              dataType: 'String'
            }
          ]}
          columnDefs={columnDefs}
        />
      </div>
    </StyledStoreConfigHeader>
  );
};

export default StoreConfigHeader;
