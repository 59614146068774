import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Chip, Button, Alert } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

import StyledProductSearch from './styles';
import ViewMoreIcon from '../../../../assets/icons/view-more.svg';
import ControlledInput from '../../../../components/ControlledInput';
import CustomButton from '../../../../components/CustomButton';
import environment from '../../../../environment';

interface IProductCodeSearchProps {
  productCodes: string[];
  errorMessage: string;
  setErrorMessage: CallableFunction;
  setProductCodes: CallableFunction;
  classes?: string;
  style?: any;
}

const ProductCodeSearch: React.FC<IProductCodeSearchProps> = ({
  productCodes,
  setProductCodes,
  errorMessage,
  setErrorMessage,
  classes,
  style
}) => {
  const [productCode, setProductCode] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkProductCodesOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expanded) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  const addProductCode = () => {
    axios
      .get(
        `${environment.apiPathForecasting}validateProduct?productCode=${productCode}`,
        {
          ...environment.params
        }
      )
      .then(
        (res) => {
          const exists = res.data.body;
          if (exists === 'false') {
            setErrorMessage('Product does not exist. ');
          } else if (exists === 'true') {
            setErrorMessage('');
            setProductCodes([...productCodes, productCode]);
          } else {
            setErrorMessage('Error encountered when querying products table');
          }
          setProductCode('');
        },
        (err) => toast.error(err.message)
      );
  };

  const removeProductCode = (product: string) => {
    setProductCodes(productCodes.filter((item) => item !== product));
  };

  useEffect(() => {
    checkProductCodesOverflow(overflowingText.current);
  }, [productCodes]);

  return (
    <StyledProductSearch expanded={expanded} style={style}>
      <div className='product-code-search'>
        <div className='product-code-search__input'>
          <ControlledInput
            id='product-code'
            placeholder='Type SKU here'
            value={productCode}
            handleChange={(event: ChangeEvent<HTMLInputElement>) =>
              setProductCode(event.target.value.trim())
            }
            label='SKU'
            type='text'
            classes='label--w-100 label--overflow-inherit'
          />
          <CustomButton
            type='button'
            classes='btn--w-10 btn--blue'
            title='Add'
            disabled={!productCode.trim()}
            handleClick={() => addProductCode()}
          />
        </div>
        {errorMessage && (
          <Alert severity='error'>
            {errorMessage}
            <a className='close-link' onClick={() => setErrorMessage('')}>
              (Close)
            </a>
          </Alert>
        )}
      </div>
      <div
        ref={overflowingText}
        className={`product-codes-container ${classes}`}
      >
        {productCodes.map((product: string) => (
          <Chip
            key={product}
            label={product}
            onDelete={() => removeProductCode(product)}
          />
        ))}
      </div>
      {viewMoreBtnVisible && (
        <Button
          onClick={() => setExpanded(!expanded)}
          className={`view-more-button ${classes}`}
        >
          <span style={{ color: 'white' }}>
            {expanded ? 'View less' : 'View more'}
          </span>
          <img src={ViewMoreIcon} alt='ViewMore' />
        </Button>
      )}
    </StyledProductSearch>
  );
};

ProductCodeSearch.defaultProps = {
  classes: '',
  style: null
};

export default ProductCodeSearch;
