import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { Button } from '@mui/material';

import StyledReplenChangeHeader from './styles';
import Disable from '../../../../assets/icons/disable.svg';

interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
}

interface IReplenChanges {
  id: number;
  productCode: string;
  productName: string;
  storeCode: string;
  storeName: string;
  replenishment: number;
  actualReplenishment: number;
  oldActual: number;
}

interface IReplenChangesProps {
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
  gridApi: GridApi | undefined;
  handleReplenChange: CallableFunction;
  actions: boolean;
}

const ReplenChanges: React.FC<IReplenChangesProps> = ({
  replenChanges,
  setReplenChanges,
  gridApi,
  handleReplenChange,
  actions
}) => {
  const removeReplenChange = (params: ICustomRemoveCellRendererParams) => {
    const rowDataNode = params.gridApi.getRowNode(String(params.data.id));
    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            actualReplenishment: params.data.oldActual
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);
      params.setReplenChanges(
        params.replenChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions remove--btn'>
        <Button
          type='button'
          onClick={() => {
            removeReplenChange(params);
          }}
        >
          <img src={Disable} alt='Remove replenishment change' />
        </Button>
      </div>
    );

    return cell;
  };
  const columnDefs: any = [
    {
      headerName: 'Recommended Replenishment',
      field: 'replenishment',
      minWidth: 100
    },
    {
      headerName: 'Actual Replenishment',
      field: 'actualReplenishment',
      onCellValueChanged: handleReplenChange,
      editable: actions,
      minWidth: 100
    },
    {
      headerName: 'Product Code',
      field: 'productCode',
      enableRowGroup: true,
      minWidth: 68
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      enableRowGroup: true,
      minWidth: 100
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      enableRowGroup: true,
      minWidth: 70
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      enableRowGroup: true,
      minWidth: 100
    }
  ];

  if (actions) {
    columnDefs.push({
      headerName: 'Actions',
      minwidth: 100,
      cellRenderer: 'renderRemoveButton',
      cellRendererParams: {
        gridApi: gridApi,
        replenChanges: replenChanges,
        setReplenChanges: setReplenChanges
      }
    });
  }

  return (
    <StyledReplenChangeHeader fixed>
      <div className='change_report report__grid ag-theme-balham'>
        <AgGridReact
          rowData={replenChanges}
          rowHeight={30}
          pagination
          defaultColDef={{
            resizable: true,
            sortable: true,
            flex: 1
          }}
          paginationPageSizeSelector={false}
          paginationPageSize={10}
          enableCellTextSelection
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
          getRowId={(params) => String(params.data.id)}
          groupDisplayType='groupRows'
          rowGroupPanelShow='always'
          suppressRowGroupHidesColumns
          excelStyles={[
            {
              id: 'numberType',
              dataType: 'Number'
            }
          ]}
          components={{ renderRemoveButton: renderRemoveButton }}
          columnDefs={columnDefs}
        />
      </div>
    </StyledReplenChangeHeader>
  );
};

export default ReplenChanges;
export type { IReplenChanges };
