import React, { SyntheticEvent, useState } from 'react';
import {
  Container,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';

import CustomButton from '../../../../components/CustomButton';
import StyledDialog from './styles';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import LoadingScreen from '../../../../components/LoadingScreen';

const ForecastingGenericModal: React.FC<{
  iconBtn?: any;
  btnTitle?: string;
  endIcon?: any;
  disabled?: boolean;
  clearForm?: () => void;
  formTitle?: string;
  formSubtitle?: any;
  formDescription?: string;
  formError?: boolean;
  warnings?: string[];
  classes?: string;
  handleSubmit: (
    setLoading: (loading: boolean) => void,
    setOpen: (open: boolean) => void
  ) => (event: SyntheticEvent) => void;
  confirmationMessage?: string;
  style?: any;
  children?: React.ReactNode;
  submitMessage: string;
}> = ({
  iconBtn,
  btnTitle,
  endIcon,
  disabled,
  clearForm,
  formTitle,
  formSubtitle,
  formDescription,
  formError,
  warnings,
  classes,
  handleSubmit,
  confirmationMessage,
  style,
  children,
  submitMessage
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);

    if (clearForm) {
      clearForm();
    }
  };

  return (
    <>
      {btnTitle && (
        <CustomButton
          title={btnTitle}
          classes={classes}
          handleClick={() => setOpen(true)}
          disabled={disabled}
          endIcon={endIcon}
        />
      )}

      {iconBtn && (
        <IconButton tabIndex={0} onClick={() => setOpen(true)}>
          <img src={iconBtn} alt='' style={style} />
        </IconButton>
      )}

      <StyledDialog
        fullScreen
        open={open}
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={handleClose}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        {confirmationMessage ? (
          <p className='dialog-confirmation'>{confirmationMessage}</p>
        ) : (
          <>
            <DialogTitle className='dialog-title'>{formTitle}</DialogTitle>

            {formSubtitle}
            {formDescription ? (
              <div className='dialog-subtitle'>
                <p>{formDescription}</p>
              </div>
            ) : (
              <hr />
            )}
          </>
        )}

        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(setLoading, setOpen)}
            className='dialog-content__form'
          >
            {warnings &&
              warnings.map((msg: string) => (
                <p className='dialog-content__form__release-warning' key={msg}>
                  <span>&#9888; </span>
                  {msg}
                </p>
              ))}
            {children}
            <CustomButton
              title={submitMessage}
              type='submit'
              classes='f-generic-modal__submit-btn'
              disabled={formError}
            />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

ForecastingGenericModal.defaultProps = {
  iconBtn: null,
  btnTitle: '',
  clearForm: undefined,
  endIcon: null,
  disabled: false,
  formTitle: '',
  formSubtitle: null,
  formDescription: '',
  formError: false,
  warnings: [],
  classes: '',
  confirmationMessage: '',
  style: null
};

export default ForecastingGenericModal;
